let oneMapBaseUrl = "https://developers.onemap.sg";
let baseUrl = "https://api.colormydiet.healthcare/api";
let openUrl = "https://prod.openapipaas.com/api";
let foodcanopyUrl = "https://foodcanopy.openapipaas.com/api/v1"; // transactional db = foodcanopy
let beveatUrl = "https://beveat.openapipaas.com/api/v1"; // lookup db = beveat
let orgId = "b7ad3a7e-513d-4f5b-a7fe-73363a3e8699";
let locationId = "b7fb763c-eadb-478c-ae87-fa4f3a71b486";

const commonConfigs = {
  // redirectUrl: "http://localhost:3000/portal/signin",
  redirectUrl: 'https://www.colormydiet.healthcare/portal/signin',
  apiHeader: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    // Authorization:``,
    "api-key": "4VlZhCASJ5Fxvtjf6KG2",
    "project-id": "15fd4502-58f1-4c10-8e29-afc9bd98db41",
  },
  oneMapUrls: {
    getAddress: (postal) =>
      `${oneMapBaseUrl}/commonapi/search?searchVal=${postal}&returnGeom=Y&getAddrDetails=Y`,
  },
  apiUrls: {
    ////////////////////////////////////////// BEGIN NEW API (ORM) //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //BEVEAT API - INDIVIDUAL CONSUMPTION
    getCalorieCounts: (org_id, consumerid, datefilter) => `${beveatUrl}/common/health/individual-consumption-trends/line-charts/calorie-counts?sso_id=${consumerid}&date_filter=${datefilter}&org_id=${org_id}&location_id=${locationId}`,
    getTopHealthy: (org_id, consumerid, datefilter) => `${beveatUrl}/common/health/individual-consumption-trends/list-views/top-healthier-items?sso_id=${consumerid}&date_filter=${datefilter}&org_id=${org_id}&location_id=${locationId}`,
    getTopUnhealthy: (org_id, consumerid, datefilter) => `${beveatUrl}/common/health/individual-consumption-trends/list-views/top-unhealthier-items?sso_id=${consumerid}&date_filter=${datefilter}&org_id=${org_id}&location_id=${locationId}`,
    getPieChartData: (org_id, consumerid, datefilter) => `${beveatUrl}/common/health/individual-consumption-trends/pie-charts/item-purchases?sso_id=${consumerid}&date_filter=${datefilter}&org_id=${org_id}&location_id=${locationId}`,
    getTransactionHistory: (org_id, consumerid, datefilter) => `${beveatUrl}/common/health/individual-consumption-trends/calorie-transactions?sso_id=${consumerid}&date_filter=${datefilter}&org_id=${org_id}&location_id=${locationId}`,

    //BEVEAT API - POPULATION CONSUMPTION
    getPopulationHeadCounts: (locationid, datefilter) => `${beveatUrl}/common/health/population-consumption-trends/line-charts/head-counts?location_id=${locationId}&date_filter=${datefilter}&org_id=${orgId}`,
    getPopulationCalorieCounts: (locationid, datefilter) => `${beveatUrl}/common/health/population-consumption-trends/line-charts/calorie-counts?location_id=${locationId}&date_filter=${datefilter}&org_id=${orgId}`,
    getPopulationTopHealthy: (locationid, datefilter) => `${beveatUrl}/common/health/population-consumption-trends/list-views/top-healthier-items?location_id=${locationId}&date_filter=${datefilter}&org_id=${orgId}`,
    getPopulationTopUnhealthy: (locationid, datefilter) => `${beveatUrl}/common/health/population-consumption-trends/list-views/top-unhealthier-items?location_id=${locationId}&date_filter=${datefilter}&org_id=${orgId}`,
    getPopulationPieChartData: (locationid, datefilter) => `${beveatUrl}/common/health/population-consumption-trends/pie-charts/item-purchases?location_id=${locationId}&date_filter=${datefilter}&org_id=${orgId}`,
    getPopulationLocations: () => `${foodcanopyUrl}/web/org/${orgId}/locations/outlets`,
    // getPopulationLocations: () => `https://stg.foodcanopy.openapipaas.com/api/v1/web/org/b7ad3a7e-513d-4f5b-a7fe-73363a3e8699/locations/outlets`,
    
    ////////////////////////////////////////// END NEW API (ORM) //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    //////////////////  COLORMYDIET ///////////////////////

    //  userLogin: () => `${baseUrl}/login`,
     userLogin: () => `${baseUrl}/colormydiet/login`,

     //dashboard
     //getOrderDetails: () => `/openapipaas/dashboard/getOrderDetails`,

    //Transaction Data
    getTxnWMerchants: () => `${openUrl}/openapipaas/stats/getTxnWMerchants`,
    deleteTxn: (txnno) => `${baseUrl}/colormydiet/stats/deleteTxn/${txnno}`,

    getDefaultTxn: (psid,orgid) => `${baseUrl}/colormydiet/stats/getDefaultConsumerTxn/${psid}/${orgid}`,
    getConsumerTxnMonth: (psid,orgid,month) => `${baseUrl}/colormydiet/stats/getConsumerTxnMonth/${psid}/${orgid}/${month}`,
    getMobileConsumerTxn: (mobile,orgid) => `${baseUrl}/colormydiet/stats/getMobileConsumerTxn/${mobile}/${orgid}`,
    getMobileTxnMonth: (mobile,orgid,month) => `${baseUrl}/colormydiet/stats/getMobileTxnMonth/${mobile}/${orgid}/${month}`,

    //PSID retrieve consumer chart {ecommerce}
    getDefault: (psid,orgid) => `${baseUrl}/colormydiet/stats/getDefaultConsumerChartDetails/${psid}/${orgid}`,
    getDaily: (psid,orgid) => `${baseUrl}/colormydiet/stats/getDailyConsumerChartDetails/${psid}/${orgid}`,
    getMonthly: (psid,orgid,month) => `${baseUrl}/colormydiet/stats/getMonthlyConsumerChartDetails/${psid}/${orgid}/${month}`,
    getMonth: (psid,orgid,month) => `${baseUrl}/colormydiet/stats/getCurrentConsumerChartDetails/${psid}/${orgid}`,

    //MOBILE retrieve consumer chart {ecommerce}
    getDefaultMobile: (mobile,orgid) => `${baseUrl}/colormydiet/stats/getMobileDefaultConsumerChartDetails/${mobile}/${orgid}`,
    getDailyMobile: (mobile,orgid) => `${baseUrl}/colormydiet/stats/getMobileDailyConsumerChartDetails/${mobile}/${orgid}`,
    getMonthlyMobile: (mobile,orgid,month) => `${baseUrl}/colormydiet/stats/getMobileMonthlyConsumerChartDetails/${mobile}/${orgid}/${month}`,
    getMonthMobile: (mobile,orgid,month) => `${baseUrl}/colormydiet/stats/getMobileCurrentConsumerChartDetails/${mobile}/${orgid}`,

    //role management
    getRoles: () => `${baseUrl}/colormydiet/role/getRole`,
    getRights: () => `${baseUrl}/colormydiet/role/getRights`,
    createRole: () => `${baseUrl}/colormydiet/role/createRole`,
    updateRole: () => `${baseUrl}/colormydiet/role/updateRole`,
    deleteRole: (roleid) => `${baseUrl}/colormydiet/role/deleteRole/${roleid}`,

    //user management
    getUsers: () => `${baseUrl}/colormydiet/users/getUser`,
    createUser: () => `${baseUrl}/colormydiet/users/createUser`,
    updateUser: () => `${baseUrl}/colormydiet/users/updateUser`,
    deleteUser: (userid) => `${baseUrl}/colormydiet/users/deleteUser/${userid}`,
    importUser: () => `${baseUrl}/colormydiet/users/importUser`,

    //dashboard
    getHeadCountChart: () =>`${baseUrl}/colormydiet/dashboard/getHeadCountChart`,
    getCalorieCountChart: () =>`${baseUrl}/colormydiet/dashboard/getCalorieCountChart`,
    getPieChart: () => `${baseUrl}/colormydiet/dashboard/getPieChart`,
    getUserDetails: () => `${baseUrl}/colormydiet/stats/getUserDetails`,

    //filter
    getFilterHeadCount: () => `${baseUrl}/colormydiet/dashboard/getFilterHeadCount`,
    getFilterCalorieCount: () =>`${baseUrl}/colormydiet/dashboard/getFilterCalorieCount`,
    getFilterTopFive:() => `${baseUrl}/colormydiet/dashboard/getfilterTopFiveFood`,
    getFilterTopFiveDetails:() => `${openUrl}/openapipaas/emenu/getfilterTopFiveItemDetails`,
    getFilterAll: () =>`${baseUrl}/colormydiet/dashboard/getFilterAll`,
    getFilterDuration: (duration) =>`${baseUrl}/colormydiet/dashboard/getFilterDuration/${duration}`,
    getFilterTopFiveDuration: (duration) =>`${openUrl}/openapipaas/emenu/getfilterTopFiveDuration/${duration}`,
    getFilterPromoDuration: (duration) =>`${openUrl}/openapipaas/promocodes/getFilterPromoDuration/${duration}`,    

    ///////////////////////// OPENAPIPASS //////////////////////////
    createUserOAPI: () => `${openUrl}/openapipaas/users/createUser`,
    updateUserOAPI: () => `${openUrl}/openapipaas/users/updateUser`,

    //dashboard
    getMenuItem: (itemid) =>
      `${openUrl}/openapipaas/emenu/getMenuItem/${itemid}`,
    getTopFiveFoodItems: (type) =>
      `${openUrl}/openapipaas/emenu/getTopFiveFoodItems/${type}`,
    getMapMarker: () => `${openUrl}/openapipaas/emenu/getMapMarker`,

    //promocodes
    getAllPromo: () => `${openUrl}/openapipaas/promocodes/getCodes`,
    getMerchantClaims: (codeid) =>
      `${openUrl}/openapipaas/promocodes/getMerchantClaims/${codeid}`,
    getItemDetails: (merchantid, codeid) =>
      `${openUrl}/openapipaas/promocodes/getItemDetails/${merchantid}/${codeid}`,
    createCode: () => `${openUrl}/openapipaas/promocodes/createCode`,
    updateCode: () => `${openUrl}/openapipaas/promocodes/updateCode`,
    deleteCode: (codeid) => `${openUrl}/openapipaas/promocodes/deleteCode/${codeid}`,

    //promocode exports
    downloadData: () => `${openUrl}/openapipaas/promocodes/downloadData`,
    uploadReceipt: () => `${openUrl}/openapipaas/promocodes/uploadReceipt`,


    //academic management - merchant management
    getMerchant: (orgid) => `${openUrl}/openapipaas/merchants/getMerchant/${orgid}`,
    getMerchantsDropdown: () => `${openUrl}/openapipaas/merchants/getMerchantsDropdown`,
    getMerchantList: (ownerid) => `${openUrl}/openapipaas/merchants/getMerchantList/${ownerid}`,
    getLocation: () => `${openUrl}/openapipaas/merchants/getOwners`,
    createMerchant: () => `${openUrl}/openapipaas/merchants/createMerchant`,
    updateMerchant: () => `${openUrl}/openapipaas/merchants/updateMerchant`,
    deleteMerchant: (merchantid) =>
      `${openUrl}/openapipaas/merchants/deleteMerchant/${merchantid}`,

    //academic management - calorie management
    getMenu: (merchantid) => `${openUrl}/openapipaas/emenu/getMenu/${merchantid}`,
    getMenuList: (merchantid) => `${openUrl}/openapipaas/emenu/getMenuList/${merchantid}`,
    createMenu: () => `${openUrl}/openapipaas/emenu/createMenu`,
    updateMenu: () => `${openUrl}/openapipaas/emenu/updateMenu`,
    deleteMenu: (itemid) => `${openUrl}/openapipaas/emenu/deleteMenu/${itemid}`,

    //addon
    getAddon: (itemid) => `${openUrl}/openapipaas/emenu/getAddon/${itemid}`,
    createAddon: () => `${openUrl}/openapipaas/emenu/createAddon`,
    updateAddon: () => `${openUrl}/openapipaas/emenu/updateAddon`,
    deleteAddon: (addonid) => `${openUrl}/openapipaas/emenu/deleteAddon/${addonid}`,

    //individual stats
    getUserItemDetails: () => `${openUrl}/openapipaas/stats/getItemDetails`,
    getFilterDate:() => `${openUrl}/openapipaas/stats/getFilterDate`,

    //audit management
    getFileUrl: (codeid,merchantid) => `${openUrl}/openapipaas/audits/getFileurl/${codeid}/${merchantid}`,
  },
};
export default commonConfigs;
