import React, { Component } from 'react';
import { Switch, Route,BrowserRouter } from 'react-router-dom';
import './common/assets/scss/style.scss';
import PrivateRoute from './auth_module/routing_module/PrivateRoute';
// import PublicRoute from './auth_module/routing_module/PublicRoute';
// import { Router } from 'react-router';
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
// const OpenAPILayout = React.lazy(() => import('./open_api_module/components/TheLayout'));
const TheLayout = React.lazy(() => import('./common/containers/TheLayout'));
const PortalLayout = React.lazy(() => import('./admin_console_module/component/ConsoleLayout'));

//Pages
const Landing = React.lazy(()=> import('./landing_module/views/LandingPage'));
const About = React.lazy(() => import('./landing_module/views/AboutPage'));
const IndivTrend = React.lazy(() => import('./old_individual_trend_module/Console'));

//AUTH PAGES
// const Login = React.lazy(() => import('./auth_module/login/LoginScreen'));
const Otp = React.lazy(()=> import('./auth_module/otp/FirebaseAuth'));
const SSASignUP = React.lazy(()=> import('./landing_module/views/SSASignUp'));
const DevSignUp =React.lazy(()=> import('./open_api_module/views/DeveloperSignUp'));
const SSASignIn = React.lazy(()=> import('./landing_module/views/SSASignIn'));
const DevSignIn = React.lazy(() => import('./open_api_module/views/DeveloperSignIn'));

//RESULT PAGES
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Success = React.lazy(() => import('./views/pages/success/register'));
const OptSucess = React.lazy(() => import('./views/pages/success/optout'));

//PORTAL CONSOLE
const PromoMgt = React.lazy(() => import('./admin_console_module/views/PromoMgt/PromoCode'));
const AuditReport = React.lazy(() => import('./admin_console_module/views/AuditMgt/AuditReportMgmt'));
const CalorieMgt = React.lazy(() => import('./admin_console_module/views/AcadMgt/CalorieMgmt'));
const IndivStats = React.lazy(() => import('./admin_console_module/views/AcadMgt/IndivStats'));
const UserMgt = React.lazy(() => import('./admin_console_module/views/UserMgt/UserMgt'));
const RoleMgt = React.lazy(() => import('./admin_console_module/views/RoleMgt/RoleMgt'));
const HelpCentre = React.lazy(() => import('./admin_console_module/views/HelpCentre'));
const AuditReimbursement = React.lazy(() => import('./admin_console_module/views/AuditMgt/AuditReimbursement'));
const PromoCodeMAR = React.lazy(() => import('./admin_console_module/views/PromoMgt/PromoCodeReinbursement'));
const CaloriesMenu = React.lazy(() => import('./admin_console_module/views/AcadMgt/CalorieMenuList'));
const CaloriesAddonMenu = React.lazy(() => import('./admin_console_module/views/AcadMgt/CalorieAddonList'));

const IndividualConsumptionTrends = React.lazy(() => import('./individual-trend-module/pages/individualTrendsPage'));

//DEVELOPERS CONSOLE
class App extends Component {

  render() {
    document.title = "ColorMyDiet";
    return (
      
      <BrowserRouter>
      <React.Suspense fallback={loading}>
            <Switch>
              {/* <PublicRoute restricted = {false} exact path="/" name="Landing Page" component={props => <Landing {...props}/>} /> */}
              <Route exact path="/about" name="About Page" component={props => <About {...props}/>} />
              <Route exact path="/" name="Landing Page" component={props => <Landing {...props}/>} />

              {/** AUTH ROUTES*/}
              {/*<PublicRoute restricted = {false} exact path="/portal/login" name="Login Page" component={props => <Login {...props}/>} />*/}
              <Route restricted = {false} exact path="/portal/otp" name="Otp Page" component={props => <Otp {...props}/>} />
              <Route exact path="/signup" name="Sign Up" component={props => <SSASignUP {...props}/>} />
              <Route exact path="/dev/signup" name="Developer Sign Up" component={props => <DevSignUp {...props}/>} />
              <Route exact path="/portal/signin" name="Portal Sign In" component={props => <SSASignIn {...props}/>} />
              <Route exact path="/developer/signin" name="Developer Sign In" component={props => <DevSignIn {...props}/>} />
              
              {/** STATUS HANDLING*/}
              <Route exact path="/portal/500" name="Page 500" component={props => <Page500 {...props}/>} />
              {/* <PublicRoute restricted = {false} exact path="/portal/404" name="Page 404" component={props => <Page404 {...props}/>} /> */}
              <Route exact path="/registerSuccess/:code" name="Success" component={props => <Success {...props}/>} />
              <Route exact path="/optSuccess" name="Success" component={props => <OptSucess {...props}/>} />
              
              {/*OPEN API ROUTES*/}
              <Route path="/documentation" name="Open API" component={props => <TheLayout {...props}/>} /> 
              <Route path="/indivchartByPsid/:psid/:orgid/:accesskey" name="Individual Trend" component={props => <IndivTrend {...props}/>} />
              <Route path="/indivchartByMobile/:mobile/:orgid/:accesskey" name="Individual Trend" component={props => <IndivTrend {...props}/>} /> 
               
            {/* https://www.colormydiet.healthcare/org/{:org_id}/individual-consumption-trends/{:sso_id} */}
            {/* http://localhost:3000/org/b7ad3a7e-513d-4f5b-a7fe-73363a3e8699/individual-consumption-trends/96f4f3da-250c-43a1-bab2-31fddf5605a6 */}
              <Route restricted={false} path="/org/:orgid/individual-consumption-trends/:ssoid" name="Individual Consumption Trends" component={props => <IndividualConsumptionTrends {...props}/>}/> 
              {/*<Route path="/portal" name="Home" component={props => <PortalLayout {...props}/>} />  */}
               
               {/*PORTAL CONSOLE*/}
               {/*<PrivateRoute path="/admin" name="Admin Console" component={props => <AdminLayout {...props}/>} />*/}
               
                <Route path="/portal" name="Dashboard" component={props => <PortalLayout {...props}/>} />
                <PrivateRoute exact path="/portal/promocode" name="Promo Code Management" component={props => <PromoMgt {...props}/>} />
                <PrivateRoute exact path="/portal/auditreport" name="Audit Report Management" component={props => < AuditReport {...props}/>} />
                <PrivateRoute exact path="/portal/caloriemanagement" name="Calorie Management" component={props => < CalorieMgt {...props}/>} />
                <PrivateRoute exact path="/portal/individualstats" name="Individual Statistics" component={props => < IndivStats {...props}/>} />
                <PrivateRoute exact path="/portal/usermanagement" name="User Management" component={props => < UserMgt {...props}/>} />
                <PrivateRoute exact path="/portal/rolemanagement" name="Role Management" component={props => < RoleMgt {...props}/>} />
                <PrivateRoute exact path="/portal/helpcentre" name="Help Centre" component={props => < HelpCentre {...props}/>} />
                <PrivateRoute exact path="/portal/auditreport/reimbursement" name="Audit Promocode Reimbursement" component={props => < AuditReimbursement {...props}/>} />
                <PrivateRoute exact path="/portal/promocode/reimbursement" name="Promocode Reimbursement" component={props => < PromoCodeMAR {...props}/>} />
                <PrivateRoute exact path="/portal/caloriemanagement/menu" name="Calories Menu" component={props => < CaloriesMenu {...props}/>} />
                <PrivateRoute exact path="/portal/caloriemanagement/menu/addon" name="Calories Add On" component={props => < CaloriesAddonMenu {...props}/>} />

            </Switch>
          </React.Suspense>
     </BrowserRouter>
    );
  }
}

export default App;
