import commonConfigs from '../../config';
import axios from 'axios';

export const isLogin = () => {
    var status = false;
    // if (localStorage.getItem('accessToken')) {
    //     axios.get(commonConfigs.apiUrls.tokenExpiry(),{headers: commonConfigs.apiHeader}).then(res => {
    //            status = true;
    //         }).catch(err =>{
    //             status = false;
    //             alert('Your session has expired. Please Login again.')
    //         });
    //         status = true;

    // }else{
    //     status = false;
    //     alert('You are not authenticated. Please Login.')
    // }

    if(localStorage.getItem('userid') !== null){
        status = true;
    }else{
        status = false;
        alert('You are not authenticated. Please Login.')
    }
    return status;
}